import { NgModule } from '@angular/core';
import { NgxErrorsDirective } from './ngxerrors.directive';
import { NgxErrorDirective } from './ngxerror.directive';
var dependencies = [
    NgxErrorsDirective,
    NgxErrorDirective
];
var NgxErrorsModule = (function () {
    function NgxErrorsModule() {
    }
    return NgxErrorsModule;
}());
export { NgxErrorsModule };
NgxErrorsModule.decorators = [
    { type: NgModule, args: [{
                declarations: dependencies.slice(),
                exports: dependencies.slice()
            },] },
];
/** @nocollapse */
NgxErrorsModule.ctorParameters = function () { return []; };
